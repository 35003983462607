export const theme = {
  colors: {
    bg: '#171c28',
    fg: '#1d2433',
    hover: '#2f3b54',
    active: '#2f3b54',
    grey: '#8695b7',
    lightGrey: '#a2aabc',
    white: '#d7dce2',
    accent: '#ffcc66',
    blue: '#5ccfe6',
    green: '#bae67e',
    orange: '#ffae57',
    yellow: '#ffd580',
    purple: '#c3a6ff',
    red: '#ef6b73',
    seafoam: '#95e6cb',
    magenta: '#c678dd',
  },
  easing: 'ease-in-out',
  transition: 'all 150ms ease-in-out',
  type: {
    baseFontSize: '16px',
    baseLineHeight: 1.666,
    scaleRatio: 3.6,
    headerFontFamily: [
      'Fira Mono',
      'Lucida Console',
      'Courier New',
      'monospace',
    ],
    bodyFontFamily: [
      'Open Sans',
      'Avenir Next',
      'Helvetica Neue',
      'Segoe UI',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    googleFonts: [
      {
        name: 'Fira Mono',
        styles: ['700'],
      },
      {
        name: 'Open Sans',
        styles: ['400', '600'],
      },
    ],
  },
}
